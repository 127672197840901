// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SSISelectCredentialPage_gradientText__vWtIi {
    background: linear-gradient(135deg, #7276F7 0%, #7C40E8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* change the size of the bullet and active color */

.SSISelectCredentialPage_swiper-pagination-bullet__x45Ug {
    background-color: red;
    display: inline-block;
    width: 2rem;
    height: 2rem;
}

/* change color of next 2 bullets in sequence to white*/

.SSISelectCredentialPage_swiper-pagination-bullet-active-next__AXMSC, .SSISelectCredentialPage_swiper-pagination-bullet-active-next-next__dG6ZW {
    background-color: white;
}

/* change color of previous bullet to white*/

.SSISelectCredentialPage_swiper-pagination-bullet-active-prev__9ZP9W {
    background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SSISelectCredentialPage/index.module.css"],"names":[],"mappings":"AAAA;IACI,6DAA6D;IAC7D,qBAAqB;IACrB,6BAA6B;IAC7B,oCAAoC;AACxC;;AAEA,mDAAmD;;AAEnD;IACI,qBAAqB;IACrB,qBAAqB;IACrB,WAAW;IACX,YAAY;AAChB;;AAEA,uDAAuD;;AAEvD;IACI,uBAAuB;AAC3B;;AAEA,4CAA4C;;AAE5C;IACI,uBAAuB;AAC3B","sourcesContent":[".gradientText {\n    background: linear-gradient(135deg, #7276F7 0%, #7C40E8 100%);\n    background-clip: text;\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}\n\n/* change the size of the bullet and active color */\n\n.swiper-pagination-bullet {\n    background-color: red;\n    display: inline-block;\n    width: 2rem;\n    height: 2rem;\n}\n\n/* change color of next 2 bullets in sequence to white*/\n\n.swiper-pagination-bullet-active-next, .swiper-pagination-bullet-active-next-next {\n    background-color: white;\n}\n\n/* change color of previous bullet to white*/\n\n.swiper-pagination-bullet-active-prev {\n    background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gradientText": `SSISelectCredentialPage_gradientText__vWtIi`,
	"swiper-pagination-bullet": `SSISelectCredentialPage_swiper-pagination-bullet__x45Ug`,
	"swiper-pagination-bullet-active-next": `SSISelectCredentialPage_swiper-pagination-bullet-active-next__AXMSC`,
	"swiper-pagination-bullet-active-next-next": `SSISelectCredentialPage_swiper-pagination-bullet-active-next-next__dG6ZW`,
	"swiper-pagination-bullet-active-prev": `SSISelectCredentialPage_swiper-pagination-bullet-active-prev__9ZP9W`
};
export default ___CSS_LOADER_EXPORT___;
