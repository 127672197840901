// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
    text-align: left;
    color: rgba(48, 48, 48, 0.8);
    width: 327px;
    height: 12px;
    margin-bottom: 6px;
}

input {
    width: 302px;
    height: 21px;
    border: none;
    outline: none;
    display: flex;
    align-items: flex-end;
    text-align: left !important;
}

input:focus::placeholder {
    color: transparent !important;
}

.SSIInformationRequestPage_enabled__GB9kZ {
    color: rgba(48, 48, 48, 0.8);
    border: 1px solid #C4C5CA;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 13px 9px 12px;
    width: 327px;
    height: 40px
}

@media (max-width: 600px) {
    .SSIInformationRequestPage_photo__zYNZ0 {
        display: none; /* Hide the div on small screens */
    }
}

/*p {*/
/*    margin: 0;*/
/*}*/
`, "",{"version":3,"sources":["webpack://./src/pages/SSIInformationRequestPage/SSIInformationRequestPage.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,4BAA4B;IAC5B,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,aAAa;IACb,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;IAC5B,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB;IACtB,2BAA2B;IAC3B,YAAY;IACZ;AACJ;;AAEA;IACI;QACI,aAAa,EAAE,kCAAkC;IACrD;AACJ;;AAEA,MAAM;AACN,iBAAiB;AACjB,IAAI","sourcesContent":["label {\n    text-align: left;\n    color: rgba(48, 48, 48, 0.8);\n    width: 327px;\n    height: 12px;\n    margin-bottom: 6px;\n}\n\ninput {\n    width: 302px;\n    height: 21px;\n    border: none;\n    outline: none;\n    display: flex;\n    align-items: flex-end;\n    text-align: left !important;\n}\n\ninput:focus::placeholder {\n    color: transparent !important;\n}\n\n.enabled {\n    color: rgba(48, 48, 48, 0.8);\n    border: 1px solid #C4C5CA;\n    border-radius: 8px;\n    box-sizing: border-box;\n    padding: 10px 13px 9px 12px;\n    width: 327px;\n    height: 40px\n}\n\n@media (max-width: 600px) {\n    .photo {\n        display: none; /* Hide the div on small screens */\n    }\n}\n\n/*p {*/\n/*    margin: 0;*/\n/*}*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"enabled": `SSIInformationRequestPage_enabled__GB9kZ`,
	"photo": `SSIInformationRequestPage_photo__zYNZ0`
};
export default ___CSS_LOADER_EXPORT___;
